import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    container: {
        marginTop: '10px',
        width: '100%',
    },
}));

export default useStyles;
