import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    FlexRow,
} from '@theme_mixins';

export default makeStyles(() => ({
    root: {
        ...FlexRow,
        borderRadius: '5px',
        background: '#F1F1F1',
        marginBottom: '4px',
    },
    labelContainer: {
        width: '100%',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '10px 10px 10px 0',
    },
    storeName: {
        margin: 0,
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: '18px',
        textTransform: 'capitalize',
    },
    storeAddress: {
        margin: 0,
        fontSize: '14px',
        lineHeight: '18px',
        textTransform: 'capitalize',
    },
    customRadio: {
        paddingLeft: '18px',
        paddingRight: '13px',
        '&.MuiRadio-root': {
            color: '#7B9AAF',
        },
        '&.MuiRadio-root.Mui-checked': {
            color: '#6BC498',
        },
        '&.MuiIconButton-root:hover': {
            background: 'none',
        },
    },
}));
