import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    CreatePadding, CreateMargin,
    Centering, FlexColumn,
} from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    checkoutBox: {
        background: '#F1F1F1',
        // ...CreatePadding(5, 18, 18, 18),
        boxShadow: '0 -1px 3px #0000001A',
        position: 'fixed',
        bottom: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        zIndex: theme.zIndex.drawer + 1,
    },
    summary: {
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center',
        padding: '0 20px',
    },
    actions: {
        ...FlexColumn,
        alignItems: 'center',
        padding: '10px 20px 20px 20px',
    },
    paypalBtn: {
        marginTop: 20,
        minWidth: 300,
    },
    goToCheckout: {
        minWidth: 300,
        width: '100%',
        borderRadius: '5px',
        background: '#185A58',
        padding: '12px',
        '& .MuiTypography-root': {
            fontSize: '22px',
            lineHeight: '14px',
        },
        '&:hover': {
            background: 'rgba(24, 90, 88, 0.8)',
        },
    },
    btnQuote: {
        marginTop: '11px',
        width: '100%',
        borderRadius: '5px',
        background: '#6BC498 !important',
        padding: '12px',
        '& .MuiTypography-root': {
            fontSize: '22px',
            lineHeight: '14px',
        },
        '&:hover': {
            background: 'rgba(107, 196, 152, 0.8)',
        },
    },
    subtotal: {
        fontSize: '20px',
        // textAlign: 'center',
        // ...CreateMargin(10, 0, 10, 0),
    },
    expand: {
        background: '#F1F1F1',
        margin: 0,
        padding: 0,
        width: '100%',
        border: 'none',
        boxShadow: 'none !important',
    },
    expanBody: {
        width: '100%',
        margin: 0,
        padding: 0,
        background: '#F1F1F1',
        ...FlexColumn,
    },

    expanHead: {
        background: '#F1F1F1',
        maxHeight: 20,
        minHeight: 15,
        ...Centering,
    },
    expandHeadOpen: {
        maxHeight: '10px !important',
        minHeight: '20px !important',
        ...Centering,
        ...CreateMargin(0, 0, 0, 0),
        ...CreatePadding(0, 0, 0, 0),
    },

    labelItem: {
        maxWidth: '50%',
        margin: 0,
    },
    itemContainer: {
        padding: '0 23px 10px 23px',
        maxHeight: '40vh',
        overflowY: 'auto',
        overflowX: 'none',
    },
    qtyOption: {
        marginTop: 0,
        marginBottom: 5,
    },
    itemFont: {
        margin: 0,
        fontSize: '14px',
        lineHeight: '20px',
    },
    action: {
        marginTop: -15,
        marginBottom: 5,
        '& .item-minus': {
            cursor: 'pointer',
            '&::after': {
                fontSize: 16,
                content: '"<"',
            },
        },
        '& .item-count': {
            padding: '0px 10px',
            fontSize: 14,
        },
        '& .item-plus': {
            cursor: 'pointer',
            fontSize: 16,
            '&::after': {
                content: '">"',
            },
        },
        '& .delete': {
            textAlign: 'right',
            '& .delete-button': {
                width: 20,
                height: 20,
            },
            '& .icon-delete': {
                fontSize: 16,
            },
        },
    },
    list: {
        padding: '13px 20px',
        margin: 0,
        borderBottom: '1px solid #E5E5E5',
    },
    totalList: {
        border: 'none',
    },
    summaryList: {
        padding: '13px 20px',
        margin: 0,
        borderTop: '1px solid #E5E5E5',
    },
    summaryWrapper: {
        width: '100%',
        background: '#F0F9F5',
        padding: '13px 12px',
        borderRadius: '0 0 5px 5px',
    },
    titleMobileCart: {
        borderBottom: '1px solid #E5E5E5',
        padding: '0 20px 18px 20px',
        '& .MuiTypography-root': {
            margin: 0,
            padding: 0,
            fontSize: '20px',
            fontWeight: 'bold',
            lineHeight: '26px',
        },
    },
    termText: {
        textAlign: 'center',
        fontSize: '12px',
        lineHeight: '14px',
        '& a': {
            color: '#6BC498',
        },
    },
}));

export default useStyles;
