import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_PRIMARY } from '@theme_color';
import {
    Centering,
    CreateBorder,
    FlexRow,
} from '@theme_mixins';

export default makeStyles(() => ({
    block: {
        ...CreateBorder(0, 0, '1px', 0, GRAY_PRIMARY),
    },
    orderCommentContainer: {
        ...FlexRow,
        alignItems: 'center',
    },
    btnAdd: {
        height: 30,
        ...Centering,
        marginLeft: 5,
    },
    rmBorder: {
        border: 'none',
    },
    textField: {
        margin: 0,
        '& .MuiFormLabel-root': {
            color: '#000000',
            fontSize: '18px',
            lineHeight: '22px',
            fontWeight: 'bold',
            marginBottom: '8px',
        },
        '& label + .MuiInput-formControl': {
            marginTop: '24px',
        },
        '& .MuiInputBase-root': {
            border: '1px solid #CBCDD1',
            borderRadius: '10px',
            background: '#FFFFFF',
            padding: '10px 12px',
            fontSize: '14px',
            color: '#000000',
        },
        '& .MuiInput-underline:before': {
            border: 'none',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: 'none',
        },
        '& .MuiInput-underline:after': {
            border: 'none',
        },
    },
    smallCircular: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -8,
        marginLeft: -8,
    },
}));
