import makeStyles from '@material-ui/core/styles/makeStyles';
import { PRIMARY } from '@theme_color';
import {
    CreateBorder,
    FlexRow,
} from '@theme_mixins';

export default makeStyles(() => ({
    root: {
        ...CreateBorder(0, 0, '1px', 0, PRIMARY),
        ...FlexRow,
    },
    rootRmBorder: {
        ...FlexRow,
        border: 'none',
    },
    labelContainer: {
        ...FlexRow,
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        cursor: 'pointer',
    },
    labelBox: {
        flex: 1,
    },
    labelContainerActive: {
        fontWeight: 'bold',
    },
    originalPrice: {
        textDecoration: 'line-through',
        marginLeft: 'auto',
    },
    originalLabel: {
        color: '#414048',
        fontSize: '14px',
    },
    promo: {
        marginLeft: 'auto',
        fontWeight: 'bold',
        color: '#414048',
        fontSize: '14px',
    },
    notPromo: {
        marginLeft: 'auto',
        fontWeight: 'normal',
        color: '#414048',
        fontSize: '14px',
    },
    customRadio: {
        '&.MuiRadio-root': {
            color: '#7B9AAF',
        },
        '&.MuiRadio-root.Mui-checked': {
            color: '#6BC498',
        },
    },
}));
