import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    DARK_GREEN, GRAY_PRIMARY, GREEN, PRIMARY,
} from '@theme_color';
import { CreateMargin, CreatePadding } from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 24,
        paddingBottom: 0,
        [theme.breakpoints.up('sm')]: {
            maxWidth: 960,
        },
        width: ' 100%',
        alignSelf: 'center',
    },
    boxField: {
        marginTop: '0px',
        marginBottom: '20px',
    },
    colorPrimary: {
        color: PRIMARY,
    },
    appBar: {
        backgroundColor: 'white',
        boxShadow: 'none',
        borderBottom: `1px solid ${GRAY_PRIMARY}`,
        flexGrow: 1,
    },
    appBarBottom: {
        bottom: 0,
        top: 'auto',
        backgroundColor: 'white',
    },
    pageTitle: {
        padding: 0,
        height: 'auto',
        marginBottom: 11,
        borderBottom: 0,
        '& > div:first-child button': {
            padding: 0,
            minWidth: 0,
            '& > span': {
                width: 'min-content',
            },
            '& svg': {
                width: 22,
                height: 22,
                color: '#2E2E2E',
            },
        },
        '& > div:nth-child(2)': {
            textAlign: 'left',
            padding: 0,
            flexGrow: 0,
        },
        '& h1': {
            fontSize: 22,
            margin: 0,
        },
    },
    address_shipping: {
        ...CreatePadding(15, 15, 15, 15),
        width: '100%',
        margin: 0,
    },
    address_billing: {
        padding: '20px 15px',
        borderBottom: `1px solid ${GRAY_PRIMARY}`,
    },
    address_title: {
        color: PRIMARY,
        fontSize: '12px',
        fontWeight: '700',
        marginBottom: '5px',
    },
    address_content: {
        fontSize: '12px',
        borderBottom: `1px solid ${GRAY_PRIMARY}`,
        paddingBottom: '15px',
    },
    address_text: {
        fontSize: '12px',
    },
    address_edit: {
        cursor: 'pointer',
        marginLeft: '57.99px',
        textDecoration: 'underline',
        fontSize: '12px',
    },
    address_action: {
        padding: '15px',
    },
    address_add: {
        backgroundColor: 'white',
        boxShadow: 'none',
        border: '1px solid black',
        fontSize: '12px',
    },
    address_save: {
        width: '100%',
        backgroundColor: PRIMARY,
        color: 'white',
        textTransform: 'uppercase',
    },
    address_drawer: {
        left: 0,
        width: '100%',
    },
    address_form: {
        overflowY: 'auto',
        paddingRight: 15,
        paddingBottom: 24,
        '&::-webkit-scrollbar': {
            width: 12,
            backgroundColor: 'transparent',
        },

        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: '#F1F1F1',
        },
        '& form > *': {
            paddingBottom: 4,
        },
        [theme.breakpoints.up('sm')]: {
            height: '80vh',
        },
    },
    form_input: {
        marginBottom: '25px',
    },
    rootAddBtn: {
        width: 'auto',
    },
    addBtn: {
        ...CreateMargin(30, 0, 30, 0),
        backgroundColor: DARK_GREEN,
        minWidth: 108,
        borderRadius: 5,
        fontSize: 16,
        textTransform: 'capitalize',
    },
    addBtnSuccess: {
        backgroundColor: GREEN,
        '&:hover': {
            backgroundColor: GREEN,
        },
        ...CreateMargin(30, 0, 30, 0),
        minWidth: 108,
        borderRadius: 0,
        fontSize: 16,
        textTransform: 'capitalize',
    },
    boxMap: {
        height: 'auto',
    },
    fontWhite: {
        color: 'white',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        textAlign: 'center',
    },
    buttonProgress: {
        color: PRIMARY,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    pinPointConfirmation: {
        fontSize: 13,
        fontWeight: 400,
    },
    linkButton: {
        color: theme.palette.text.link,
        textDecoration: 'underline',
    },
    onlyView: {
        '& input[disabled], & .MuiFormLabel-root.Mui-disabled': {
            color: 'black',
        },
        '& .MuiAutocomplete-endAdornment': {
            display: 'none',
        },
    },
}));

export default useStyles;
