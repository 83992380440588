import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    Centering,
} from '@theme_mixins';

export default makeStyles(() => ({
    block: {
        marginBottom: '12px',
    },
    fieldPoinContainer: {
        border: '1px solid #CBCDD1',
        borderRadius: '5px',
        padding: '12px 14px',
        position: 'relative',
    },
    btnWrapper: {
        position: 'absolute',
        top: '10px',
        right: '0',
    },
    btnAplly: {
        height: 30,
        ...Centering,
        border: 'none',
        background: 'transparent',
        '&.MuiButton-outlined.Mui-disabled': {
            border: 'none',
        },
        '&.MuiButton-outlinedPrimary:hover': {
            border: 'none',
            background: 'none',
        },
    },
    btnText: {
        color: '#6BC498',
    },
    rmBorder: {
        border: 'none',
        padding: 0,
    },
    smallCircular: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -8,
        marginLeft: -8,
    },
    customText: {
        width: '100%',
        margin: 0,
        '& .MuiInput-underline:before': {
            border: 'none',
        },
        '& label + .MuiInput-formControl': {
            margin: 0,
        },
        '& p': {
            margin: 0,
        },
        '& .MuiInputBase-input': {
            padding: 0,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: 'none',
        },
        '& .MuiInput-underline:after': {
            border: 'none',
        },
        '& input::placeholder': {
            color: '#989898',
            fontSize: '14px',
        },
    },
}));
