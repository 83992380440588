import Radio from '@common_forms/Radio';
import Typography from '@common_typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { formatPrice } from '@helper_currency';
import DeliveryItem from '@core_modules/checkout/components/radioitem';
import useStyles from '@core_modules/checkout/pages/default/components/style';

const Loader = () => (
    <>
        <Skeleton variant="rect" width="100%" height={20} animation="wave" style={{ marginBottom: 10 }} />
        <Skeleton variant="rect" width="100%" height={20} animation="wave" style={{ marginBottom: 10 }} />
        <Skeleton variant="rect" width="100%" height={20} animation="wave" style={{ marginBottom: 10 }} />
    </>
);

// const ShippingGroupIcon = (props) => {
//     const { baseMediaUrl, src } = props;
//     const fallbacks = [`${baseMediaUrl}checkout/shipping/shipping-${src.replace('sg-', '')}.svg`, null];
//     const styles = useStyles();

//     // check if image exist on the backoffice, otherwise use fallback image from PWA
//     const [imageSrc, setImageSrc] = React.useState(`./assets/img/shipping-${src.replace('sg-', '')}.svg`);
//     const [fallbackImageIndex, setFallbackImageIndex] = React.useState(0);

//     // set image fallback url
//     const getFallbackImageSrc = () => {
//         if (fallbackImageIndex > fallbacks.length) {
//             return;
//         }
//         setImageSrc(fallbacks[fallbackImageIndex]);
//         setFallbackImageIndex(fallbackImageIndex + 1);
//     };

//     return (
//         <>
//             {(imageSrc && (
//                 <img
//                     className={styles.shippingGroupStyleIcon}
//                     src={imageSrc}
//                     alt={src.replace('sg-', '')}
//                     onError={() => getFallbackImageSrc()}
//                 />
//             ))
//                 || ''}
//         </>
//     );
// };

const ShippingView = (props) => {
    const styles = useStyles();
    const {
        isOnlyVirtualProductOnCart, checkout, storeConfig, loading, selected,
        handleShipping, data, t,
    } = props;
    let content;
    if (checkout.selected.delivery === 'pickup') {
        const price = formatPrice(0, storeConfig.base_currency_code || 'IDR');
        content = <DeliveryItem value={{ price }} label={t('checkout:pickupStore')} selected borderBottom={false} />;
    } else if (checkout.selected.delivery === 'instore') {
        const price = formatPrice(0, storeConfig.base_currency_code || 'IDR');
        content = <DeliveryItem value={{ price }} label={t('checkout:instorePickup')} selected borderBottom={false} />;
    } else if (loading.shipping || loading.addresses || loading.all) {
        content = <Loader />;
    } else if (data.shippingMethods.length !== 0) {
        content = (
            <div className={styles.expansionShippingContainer}>
                <Radio
                    value={selected.shipping}
                    onChange={handleShipping}
                    classContainer={styles.radioShiping}
                    CustomItem={DeliveryItem}
                    valueData={data.shippingMethods}
                    propsItem={{
                        borderBottom: false,
                        classContent: styles.listShippingGroup,
                    }}
                />
            </div>
        );
    } else {
        content = <Typography variant="p">{t('checkout:noShipping')}</Typography>;
    }

    return isOnlyVirtualProductOnCart ? null : (
        <div className={styles.shippingBlock} id="checkoutShipping">
            <Typography variant="title" type="bold" letter="uppercase">
                {/* {t('checkout:shippingMethod')} */}
                Kurir Pengiriman
            </Typography>
            {content}
        </div>
    );
};

export default ShippingView;
