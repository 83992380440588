/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Button from '@common_button';
import Typography from '@common_typography';
import Thumbor from '@common_image';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { formatPrice } from '@helper_currency';
import classNames from 'classnames';
import Skeleton from '@material-ui/lab/Skeleton';

import useStyles from '@plugin_summary/components/DesktopSummary/style';
import PaypalButtonView from '@plugin_paypalbutton';
import { checkB2BPermission, getB2BData } from '@root/src/helpers/b2b';

const Summary = (props) => {
    const {
        t, summary, isChekoutPage = false, handleActionSummary = () => { }, loading, disabled,
        showItems = false, items = [], hideButton = false, isDesktop,
        isLoader, /* deleteCart, updateCart, */ withAction, withLabel = true,
        labelItemAlign = 'left', dataCart, storeConfig, onAddQuoteClick,
    } = props;
    const styles = useStyles();
    const [openItem, setOpenItem] = React.useState(false);
    const b2bData = getB2BData();
    const showQuotation = dataCart?.is_request_quote_button_available;

    const containerStyle = isChekoutPage
        ? styles.containerCheckout : styles.container;

    const listStyle = isChekoutPage
        ? styles.listCheckout : styles.list;

    const listWrapperStyle = isChekoutPage
        ? styles.listWrapperCheckout : styles.listWrapper;

    const totalSummary = isChekoutPage
        ? styles.summaryList : styles.list;

    const Loader = () => (
        <div id="desktopSummary" className={isDesktop ? classNames(styles.container, 'hidden-mobile') : styles.container}>
            <Typography variant="h1" type="regular" letter="capitalize">
                {t('common:summary:title')}
            </Typography>
            <ListItem className={classNames(styles.list, 'listSummary')}>
                <Skeleton variant="rect" width="100%" height="30px" animation="wave" />
            </ListItem>
            <ListItem className={classNames(styles.list, 'listSummary')}>
                <Skeleton variant="rect" width="100%" height="30px" animation="wave" />
            </ListItem>
            <ListItem className={classNames(styles.list, 'listSummary')}>
                <Skeleton variant="rect" width="100%" height="30px" animation="wave" />
            </ListItem>
            <ListItem className={classNames(styles.list, 'listSummary')}>
                <ListItemText
                    primary={(
                        <Typography variant="title" type="bold">
                            Order Total
                        </Typography>
                    )}
                />
                <Skeleton variant="rect" width="60%" height="30px" animation="wave" />
            </ListItem>
        </div>
    );
    if (isLoader) {
        return <Loader />;
    }

    return (
        <div id="desktopSummary" className={isDesktop ? classNames(containerStyle, 'hidden-mobile') : containerStyle}>
            {withLabel && (
                <Typography className={styles.summaryTitle} variant="h1" letter="capitalize">
                    {t('common:summary:title')}
                </Typography>
            )}
            {showItems ? (
                <>
                    <div className={classNames(styles.itemSummary)} onClick={() => setOpenItem(!openItem)}>
                        <div>
                            <Typography
                                className={styles.cartItemInfo}
                                size="14"
                                variant="span"
                            >
                                {`${dataCart.total_quantity} item di Keranjang`}
                            </Typography>
                        </div>
                        <div>{openItem ? <ExpandLess size="small" /> : <ExpandMore size="small" />}</div>
                    </div>
                    {openItem ? (
                        <div className={classNames('row', styles.itemOpen)}>
                            {items.map((item, index) => (
                                <div
                                    id="listItemProductSummary"
                                    className={classNames('col-xs-12 row between-xs', styles.listProduct)}
                                    key={index}
                                >
                                    {/* {withAction && (
                                        <div
                                            className="delete"
                                            onClick={() => {
                                                deleteCart(item.id);
                                            }}
                                        >
                                            x
                                        </div>
                                    )} */}
                                    <div className="col-xs-4">
                                        <Thumbor
                                            className="product-image-photo"
                                            src={item.product.small_image.url}
                                            alt={item.product.name}
                                            width={61}
                                            height={75}
                                        />
                                    </div>
                                    <div className={classNames('col-xs-8', styles.bodyProductItem)}>
                                        <Typography variant="span">{item.product.name}</Typography>
                                        {item.configurable_options && item.configurable_options.length ? (
                                            <div className="product-options">
                                                {item.configurable_options.map((val, idx) => (
                                                    <div className="option-wrapper" key={idx}>
                                                        <strong>{val.option_label}</strong>
                                                        {' '}
                                                        :
                                                        {val.value_label}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : null}
                                        <div className="flex-grow" />
                                        {withAction && (
                                            <div>
                                                {/* <span
                                                    className="item-minus qty-update"
                                                    onClick={() => {
                                                        if (item.quantity > 1) {
                                                            updateCart(item.id, item.quantity - 1);
                                                        }
                                                    }}
                                                /> */}
                                                <Typography variant="span" size="14">
                                                    Qty:
                                                    {item.quantity}
                                                </Typography>

                                                {/* <span
                                                    className="item-plus qty-update"
                                                    onClick={() => {
                                                        updateCart(item.id, item.quantity + 1);
                                                    }}
                                                /> */}
                                            </div>
                                        )}
                                        <Typography variant="span" size="14" letter="uppercase">
                                            {item.prices.row_total.value === 0
                                                ? t('common:title:free')
                                                : formatPrice(item.prices.row_total.value, item.prices.row_total.currency || 'IDR')}
                                        </Typography>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : null}
                </>
            ) : null}
            <List className={listWrapperStyle}>
                {summary.data.map((dt, index) => (
                    <ListItem className={classNames(listStyle, 'listSummary')} key={index}>
                        <ListItemText
                            className={styles.labelItem}
                            primary={(
                                <Typography className={styles.contentText} variant="p" letter="capitalize" size="12" align={labelItemAlign}>
                                    {dt.item}
                                </Typography>
                            )}
                        />
                        <ListItemSecondaryAction>
                            <Typography className={styles.contentText} variant="span" type="regular">
                                {dt.value}
                            </Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
                <ListItem className={classNames(totalSummary, 'listSummary')}>
                    {
                        isChekoutPage ? (
                            <div className={styles.summaryWrapper}>
                                <ListItemText
                                    className={styles.labelItem}
                                    primary={(
                                        <Typography className={styles.summaryText} variant="title" type="bold" align={labelItemAlign}>
                                            {/* { isChekoutPage ? 'Order Total' : 'Total' } */}
                                            Order Total
                                        </Typography>
                                    )}
                                />
                                <ListItemSecondaryAction>
                                    <Typography className={styles.summaryText} variant="title" type="bold">
                                        {summary.total.currency ? formatPrice(summary.total.value, summary.total.currency) : null}
                                    </Typography>
                                </ListItemSecondaryAction>
                            </div>
                        ) : (
                            <>
                                <ListItemText
                                    className={styles.labelItem}
                                    primary={(
                                        <Typography className={styles.summaryText} variant="title" type="bold" align={labelItemAlign}>
                                            {/* { isChekoutPage ? 'Order Total' : 'Total' } */}
                                            Order Total
                                        </Typography>
                                    )}
                                />
                                <ListItemSecondaryAction>
                                    <Typography className={styles.summaryText} variant="title" type="bold">
                                        {summary.total.currency ? formatPrice(summary.total.value, summary.total.currency) : null}
                                    </Typography>
                                </ListItemSecondaryAction>
                            </>
                        )
                    }
                </ListItem>
            </List>
            <div className={styles.footer}>
                {
                    !hideButton ? (
                        <Button loading={loading} disabled={disabled} className={styles.btnCheckout} onClick={handleActionSummary}>
                            <Typography variant="span" color="white" type="bold" letter="capitalize">
                                {t('common:button:checkout')}
                            </Typography>
                        </Button>
                    ) : null
                }
                {
                    !hideButton && dataCart && (
                        <div className={styles.paypalBtn}>
                            <PaypalButtonView cart={dataCart} t={t} storeConfig={storeConfig} />
                        </div>
                    )
                }
                {/* Quotation Button Here */}
                {
                    !hideButton && showQuotation && b2bData && b2bData.status && checkB2BPermission('company_quotes_allow_using') ? (
                        <Button className={styles.btnQuote} onClick={onAddQuoteClick}>
                            <Typography variant="span" color="white" type="bold" letter="capitalize">
                                {t('cart:addQuoteButton')}
                            </Typography>
                        </Button>
                    ) : null
                }
            </div>
        </div>
    );
};

export default Summary;
