import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    GREEN, WHITE,
} from '@theme_color';
import {
    FlexColumn,
    CreateBorder,
    Centering,
} from '@theme_mixins';

const useStyles = makeStyles(() => ({
    container: {
        background: '#F1F1F1',
        width: '100%',
        height: 'auto',
        padding: 0,
        ...FlexColumn,
        position: 'sticky',
        top: 100,
        borderRadius: '10px',
    },
    containerCheckout: {
        background: '#FFF',
        width: '100%',
        height: 'auto',
        padding: 0,
        ...FlexColumn,
        position: 'sticky',
        top: 100,
        borderRadius: '10px',
        border: '1px solid #F1F1F1',
    },
    itemSummary: {
        padding: '0 21px 19px 21px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
        borderBottom: '1px solid #F1F1F1',
    },
    cartItemInfo: {
        padding: 0,
        margin: 0,
    },
    itemOpen: {
        padding: '0 21px',
    },
    summaryTitle: {
        margin: '18px 21px',
        fontWeight: 'bold',
        fontSize: '20px',
    },
    listWrapper: {
        padding: 0,
    },
    listWrapperCheckout: {
        padding: '19px 0',
    },
    list: {
        ...CreateBorder('1px', 0, 0, 0, '#E5E5E5'),
        padding: '18px',
    },
    listCheckout: {
        padding: '0 21px',
    },
    summaryList: {
        // ...CreateBorder('1px', 0, 0, 0, '#E5E5E5'),
        // border: '1px solid blue',
        marginTop: '19px',
        padding: '0 7px',
        // background: '#F0F9F5'
    },
    summaryWrapper: {
        width: '100%',
        background: '#F0F9F5',
        padding: '13px 12px',
        borderRadius: '0 0 5px 5px',
    },
    footer: {
        width: '100%',
        ...FlexColumn,
        alignItems: 'center',
        // paddingBottom: '20px',
    },
    btnCheckout: {
        // marginTop: 20,
        minWidth: '90%',
        marginBottom: 11,
        borderRadius: '5px',
        background: '#185A58',
        padding: '12px',
        '& .MuiTypography-root': {
            fontSize: '22px',
            lineHeight: '14px',
        },
        '&:hover': {
            background: 'rgba(24, 90, 88, 0.8)',
        },
    },
    btnQuote: {
        minWidth: '90%',
        marginBottom: 20,
        borderRadius: '5px',
        background: '#6BC498 !important',
        padding: '12px',
        '& .MuiTypography-root': {
            fontSize: '22px',
            lineHeight: '14px',
        },
        '&:hover': {
            background: 'rgba(107, 196, 152, 0.8)',
        },
    },
    paypalBtn: {
        minWidth: '90%',
    },
    labelItem: {
        maxWidth: '50%',
    },
    contentText: {
        fontSize: '14px',
        margin: 0,
    },
    summaryText: {
        fontSize: '20px',
    },
    expanItem: {
        background: 'transparent',
        borderRadius: 0,
        border: 'none',
        boxShadow: 'none',
    },

    listProduct: {
        padding: 20,
        position: 'relative',
        '& .delete': {
            position: 'absolute',
            top: 22,
            right: 0,
            fontSize: 15,
            cursor: 'pointer',
            width: 10,
            height: 10,
        },
    },
    imgProduct: {
        width: 75,
        height: 'auto',
    },
    imgBox: {
        position: 'relative',
    },
    freeItem: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 1,
        minWidth: 15,
        minHeight: 10,
        backgroundColor: GREEN,
        color: WHITE,
        fontWeight: '700',
        fontSize: 8,
        padding: 2,
        borderRadius: 3,
        ...Centering,
        marginLeft: 'auto',
        marginRight: 5,
        textTransform: 'uppercase',
    },
    bodyProductItem: {
        ...FlexColumn,
        '& .item-minus': {
            cursor: 'pointer',
            '&::after': {
                content: '"<"',
            },
        },
        '& .item-count': {
            padding: '0px 10px',
        },
        '& .item-plus': {
            cursor: 'pointer',
            '&::after': {
                content: '">"',
            },
        },
        '& .product-options': {
            margin: '5px',
            '& .option-wrapper': {
                fontSize: 10,
                '& .option-wrapper__item': {
                    paddingLeft: 10,
                },
                '& .option-item': {
                    margin: 0,
                    marginLeft: 5,
                },
            },
        },
    },
}));

export default useStyles;
