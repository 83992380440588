import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(() => ({
    card: {
        marginBottom: 15,
        marginLeft: 0,
        marginRight: 0,
        '& strong': {
            fontWeight: '500',
        },
        '& td': {
            fontSize: 12,
        },
    },
    labelInfoPickup: {
        margin: '0 0 10px 0',
        padding: 0,
        fontSize: '18px',
        lineHeight: '22px',
        fontWeight: 'bold',
    },
}));
