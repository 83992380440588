import React from 'react';
import TagManager from 'react-gtm-module';
import gqlService from '@core_modules/checkout/services/graphql';

const Shipping = (props) => {
    const {
        t,
        checkout,
        setCheckout,
        updateFormik,
        handleOpenMessage,
        storeConfig,
        isOnlyVirtualProductOnCart,
        ShippingView,
        checkoutTokenState,
        setCheckoutTokenState,
    } = props;

    const { loading, data, selected } = checkout;
    const [setShippingMethodsVendor] = gqlService.setShippingMethodsVendor();

    const handleShipping = async (val) => {
        if (val) {
            const { cart } = checkout.data;
            const [carrier_code, method_code, vendor_code = ''] = val.split('_');
            let state = {
                ...checkout,
                loading: {
                    ...checkout.loading,
                    all: false,
                    shipping: false,
                    extraFee: true,
                    order: true,
                },
            };
            state.selected.shipping = val;
            setCheckout(state);

            let updatedCart = {};
            await setShippingMethodsVendor({
                variables: {
                    cartId: cart.id,
                    carrierCode: carrier_code,
                    methodCode: method_code,
                    vendorCode: vendor_code,
                },
            }).then((res) => {
                updatedCart = res;
            }).catch((err) => {
                updatedCart = err;
            });

            state = {
                ...checkout,
                loading: {
                    ...checkout.loading,
                    all: false,
                    shipping: false,
                    payment: false,
                    extraFee: false,
                    order: false,
                },
            };
            setCheckout(state);

            if (updatedCart
                && updatedCart.data
                && updatedCart.data.setShippingMethodsVendorOnCart
                && updatedCart.data.setShippingMethodsVendorOnCart.cart
            ) {
                updatedCart = {
                    ...checkout.data.cart,
                    ...updatedCart.data.setShippingMethodsVendorOnCart.cart,
                };
                updateFormik(updatedCart);

                const paymentMethod = updatedCart.available_payment_methods.map((method) => ({
                    ...method,
                    label: method.title,
                    value: method.code,
                    image: null,
                }));

                state = { ...checkout };
                state.data.paymentMethod = paymentMethod;
                state.data.cart = updatedCart;
                setCheckout(state);
                const selectedShipping = data.shippingMethods.filter((item) => item.method === method_code);
                // GA 4 dataLayer
                const dataLayerOpt = {
                    event: 'add_shipping_info',
                    ecommerce: {
                        shipping_tier: selectedShipping,
                        items: [
                            cart.items.map(({ quantity, product, prices }) => ({
                                currency: storeConfig.base_currency_code || 'IDR',
                                item_name: product.name,
                                item_id: product.sku,
                                price: JSON.stringify(prices.price.value),
                                item_category: product.categories.length > 0 ? product.categories[0].name : '',
                                item_list_name: product.categories.length > 0 ? product.categories[0].name : '',
                                quantity: JSON.stringify(quantity),
                                item_stock_status: product.stock_status === 'IN_STOCK' ? 'In stock' : 'Out stock',
                                item_sale_product: '',
                                item_reviews_count: '',
                                item_reviews_score: '',
                            })),
                        ],
                    },
                };
                TagManager.dataLayer({
                    dataLayer: dataLayerOpt,
                });
            } else {
                state.selected.shipping = null;
                if (updatedCart.message.includes('Token is wrong.')) {
                    setCheckoutTokenState(!checkoutTokenState);
                } else {
                    handleOpenMessage({
                        variant: 'error',
                        text: t('checkout:message:problemConnection'),
                    });
                }
            }
        }
    };

    return (
        <ShippingView
            checkout={checkout}
            storeConfig={storeConfig}
            t={t}
            handleShipping={handleShipping}
            loading={loading}
            selected={selected}
            data={data}
            isOnlyVirtualProductOnCart={isOnlyVirtualProductOnCart}
        />
    );
};

export default Shipping;
