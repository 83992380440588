/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Typography from '@common_typography';
import useStyles from '@core_modules/checkout/pages/default/components/PickupInformation/style';
import ModalSelectStore from '@core_modules/checkout/pages/default/components/ModalSelectStore';
import gqlService from '@core_modules/checkout/services/graphql';
import useParentStyles from '@core_modules/checkout/pages/default/components/style';

const PickupInformation = (props) => {
    const {
        t, checkout, setCheckout,
    } = props;
    const classes = useStyles();
    const styles = useParentStyles();
    const { cart } = checkout.data;
    let listStores = [];
    const pickupStores = gqlService.getPickupStore({
        variables: { cart_id: cart.id },
        skip: typeof cart === 'undefined',
    });
    if (!pickupStores.loading && pickupStores.data && !pickupStores.error) {
        listStores = pickupStores.data.getPickupStore.store;
    }
    return (
        <div className={styles.shippingBlock} id="checkoutPickupStore">
            <Typography className={classes.labelInfoPickup}>
                {/* {t('checkout:pickupInformation:pickupAtLabel')} */}
                Lokasi Pengambilan
            </Typography>
            <ModalSelectStore
                t={t}
                setCheckout={setCheckout}
                checkout={checkout}
                listStores={listStores}
            />
        </div>
    );
};

export default PickupInformation;
