/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Typography from '@common_typography';
import classNames from 'classnames';
import useStyles from '@core_modules/checkout/pages/default/components/delivery/style';
import useStylesRoot from '@core_modules/checkout/pages/default/components/style';
import { modules } from '@config';

const ShippingView = (props) => {
    const {
        checkout, handleSelect, t,
    } = props;
    const classes = useStyles();
    const styles = useStylesRoot();
    const checkStyles = (delivery) => ((checkout.selected.delivery === delivery)
        ? classNames(classes.item, classes.active, `${delivery}Delivery`)
        : classNames(classes.item, `${delivery}Delivery`));

    return (
        <div id="checkoutDeliveryMethod" className={classNames(styles.block, styles.mobileBlock)}>
            <Typography className={styles.titleMobile} variant="title" type="bold" letter="capitalize">
                {/* {t('checkout:deliveryMethod:label')} */}
                pilih Metode pengiriman
            </Typography>
            <div className="row">
                <div className="col-xs-6">
                    <div className={checkStyles('home')} onClick={() => handleSelect('home')}>
                        <div className={classNames('column', classes.itemContent)}>
                            <div>
                                {
                                    checkout.selected.delivery === 'home' ? (
                                        <img className={classes.imageIcon} src="/assets/img/deliveryIcon.svg" alt="deliveryActive" />
                                    ) : (
                                        <img className={classes.imageIcon} src="/assets/img/deliveryIconDeactive.svg" alt="delivery" />
                                    )
                                }
                            </div>
                            <Typography className={classes.mainTitle} variant="span" type="bold">
                                {t('checkout:deliveryMethod:homeDelivery')}
                            </Typography>
                            <Typography className={classNames(classes.subText)}>
                                {/* {t('checkout:deliveryMethod:homeDeliveryDesc')} */}
                                order dikirim langsung ke alamat anda
                            </Typography>
                        </div>
                    </div>
                </div>
                {modules.checkout.pickupStore.enabled && (
                    <div className="col-xs-6">
                        <div className={checkStyles('pickup')} onClick={() => handleSelect('pickup')}>
                            <div className={classNames('column', classes.itemContent)}>
                                <div>
                                    {
                                        checkout.selected.delivery === 'pickup' ? (
                                            <img className={classes.imageIcon} src="/assets/img/pickupIconActive.svg" alt="pickupActive" />
                                        ) : (
                                            <img className={classes.imageIcon} src="/assets/img/pickupIcon.svg" alt="pickup" />
                                        )
                                    }
                                </div>
                                <Typography className={classes.mainTitle} variant="span" type="bold">
                                    {t('checkout:deliveryMethod:pickupDelivery')}
                                </Typography>
                                <Typography className={classNames(classes.subText)}>
                                    {/* {t('checkout:deliveryMethod:pickupDeliveryDesc')} */}
                                    order diambil di warehouse CSA
                                </Typography>
                            </div>
                        </div>
                    </div>
                )}
                {modules.checkout.inStorePickup.enabled && (
                    <div className="col-xs-6">
                        <div className={checkStyles('instore')} onClick={() => handleSelect('instore')}>
                            <div className="column">
                                <Typography variant="span" type="bold">
                                    {t('checkout:deliveryMethod:instorePickup')}
                                </Typography>
                                <Typography className="hidden-mobile">
                                    {t('checkout:deliveryMethod:instorePickupDesc')}
                                </Typography>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ShippingView;
