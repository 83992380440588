import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    CreateBorder,
    CreatePadding,
    FlexRow,
} from '@theme_mixins';

export default makeStyles(() => ({
    item: {
        ...CreatePadding(15, 0, 15, 0),
        ...FlexRow,
        justifyContent: 'space-between',
        ...CreateBorder('1px', '1px', '1px', '1px', '#F1F1F1'),
        borderRadius: 10,
        cursor: 'pointer',
        minHeight: '130px',
    },
    itemContent: {
        width: '100%',
        textAlign: 'center',
    },
    imageIcon: {
        height: '32px',
        width: '32px',
        textAlign: 'center',
    },
    mainTitle: {
        margin: 0,
        color: '#989898',
        fontSize: 14,
        textAlign: 'center',
    },
    subText: {
        margin: 0,
        textAlign: 'center',
        color: '#989898',
        fontSize: 12,
        lineHeight: '18px',
    },
    active: {
        ...CreateBorder('1px', '1px', '1px', '1px', '#6BC498'),
        '& .MuiTypography-root:nth-child(2)': {
            color: '#185A58',
        },
    },
}));
