import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_PRIMARY, PRIMARY } from '@theme_color';
import {
    Centering,
    CreateBorder,
    CreatePadding,
    FlexColumn,
    FlexRow,
    CreateMargin,
} from '@theme_mixins';

export default makeStyles((theme) => ({
    root: {
        ...FlexColumn,
        height: '100vh',
        width: '100%',
    },
    container: {
        width: '100%',
        height: 'auto',
        paddingBottom: 150,
    },
    pageContainer: {
        width: '100%',
        margin: 0,
        padding: '0 196px',
        [theme.breakpoints.down('sm')]: {
            padding: '0',
        },
    },
    pageTitle: {
        padding: '0 196px',
        margin: '23px 0 18px 0',
        fontSize: '30px',
        fontWeight: 'bold',
        lineHeight: '55px',
        [theme.breakpoints.down('sm')]: {
            padding: '0',
            margin: '18px 0 12px 0',
            textAlign: 'center',
        },
    },
    block: {
        ...CreatePadding(23, 23, 23, 23),
        marginBottom: '8px',
        borderRadius: '10px',
        background: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            borderTop: '1px solid #F1F1F1',
        },
    },
    mobileBlock: {
        [theme.breakpoints.down('sm')]: {
            ...CreatePadding(0, 23, 23, 23),
            border: 'none',
        },
    },
    titleMobile: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    shippingBlock: {
        ...CreatePadding(23, 23, 23, 23),
        borderTop: '1px solid #F1F1F1',
    },
    emailInfoText: {
        ...CreateMargin(0, 0, 7, 0),
        ...CreatePadding(0, 0, 0, 0),
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: '22px',
    },
    emailCustomer: {
        ...CreateMargin(0, 0, 0, 0),
        ...CreatePadding(0, 0, 0, 0),
        fontSize: '14px',
        lineHeight: '18px',
    },
    addressContainer: {
        ...FlexRow,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    addressText: {
        ...FlexColumn,
        maxWidth: '60%',
    },
    listShipping: {
        ...CreateBorder('1px', 0, 0, 0, PRIMARY),
    },
    listShippingGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        width: '100%',
        boxSizing: 'inherit',
    },
    radioShiping: {
        width: '100% !important',
    },
    btnPoint: {
        maxWidth: 140,
        ...Centering,
        padding: 5,
    },
    cardPoint: {
        ...CreateMargin(5, 0, 5, 0),
        ...CreatePadding(17, 17, 17, 17),
        ...FlexRow,
        alignItems: 'center',
        justifyContent: 'space-between',
        ...CreateBorder('1px', '1px', '1px', '1px', GRAY_PRIMARY),
        borderRadius: 10,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
    pointText: {
        fontSize: 20,
        ...CreateMargin(0, 0, 0, 5),
    },
    btnBalanceGift: {
        ...CreateMargin(-25, 0, 0, -5),
    },
    rmBorder: {
        border: 'none',
    },
    smallCircular: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -8,
        marginLeft: -8,
    },
    mediumCircular: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    largeCircular: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -15,
        marginLeft: -15,
    },
    customFormControl: {
        marginTop: '10px',
        marginBottom: '20px',
    },
    emailContainer: {
        margin: '5px',
    },
    giftCardContainer: {
        position: 'relative',
    },
    giftcardInfoContainer: {
        ...CreateMargin(-30, 10, 30, 5),
    },
    giftCard: {
        marginLeft: '5px',
        marginRight: '5px',
    },
    paymentExpansionContainer: {
        marginTop: '10px',
    },
    placeOrderDesktop: {
        maxWidth: 500,
        // height: 50,
        width: '100%',
        padding: '12px',
        background: '#185A58',
        borderRadius: '10px',
        '& .MuiTypography-root': {
            fontSize: '22px',
            lineHeight: '14px',
        },
        '&.MuiButton-contained.Mui-disabled': {
            background: '#F1F1F1',
            '& .MuiTypography-root': {
                color: '#989898',
            },
        },
    },
    labelAccordion: {
        ...FlexRow,
        alignItems: 'center',
    },
    shippingGroupStyleIcon: {
        width: '45px',
        height: '45px',
        marginRight: '10px',
    },
    labelSummary: {
        ...FlexRow,
        alignItems: 'center',
    },
    paymentGroupStyleIcon: {
        width: '45px',
        height: '45px',
        marginRight: '10px',
    },
    mobileBottomSpace: {
        background: '#F8F8F8',
        margin: 0,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            background: '#FFF',
            marginBottom: 70,
        },
    },
    expansionPanel: {
        border: 'none !important',
        borderRadius: '5px !important',
        background: '#F1F1F1',
    },
    expansionPanelSummary: {
        border: 'none',
        borderBottom: 'none !important',
    },
    expansionPanelDetail: {
        // border: '1px solid blue'
    },
    expansionGridContainer: {
        border: '1px solid #CBCDD1',
        borderRadius: '5px',
        background: '#FFFFFF',
    },
    expansionShippingContainer: {
        paddingRight: '17px',
        paddingTop: '7px',
        paddingBottom: '7px',
        paddingLeft: '8px',
        borderRadius: '5px',
        background: '#F1F1F1',
    },
    expansionGridItem: {
        '& > div': {
            margin: 0,
        },
    },
    paymentHeader: {
        ...FlexRow,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    paymentHeaderTitle: {
        margin: 0,
        fontSize: '18px',
        lineHeight: '22px',
    },
    howToPay: {
        cursor: 'pointer',
        color: '#6BC498',
        fontSize: '12px',
        lineHeight: '14px',
    },
    listError: {
        ...FlexColumn,
        marginTop: 20,
    },
    promoTitle: {
        padding: 0,
        margin: '0 0 4px 0',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    promoHeaderText: {
        padding: 0,
        margin: '0 0 12px 0',
        fontSize: '18px',
        lineHeight: '22px',
    },
    termText: {
        textAlign: 'center',
        fontSize: '12px',
        lineHeight: '14px',
        '& a': {
            color: '#6BC498',
            cursor: 'pointer',
        },
    },
    dateInfoTitle: {
        margin: '0 0 10px 0',
        padding: 0,
        fontWeight: 'bold',
        textTransform: 'capitalize',
        fontSize: '18px',
        lineHeight: '22px',
    },
    datePickerWrapper: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #F1F1F1',
        borderRadius: '5px',
        width: '100%',
    },
    datePicker: {
        width: '100%',
        padding: '13px',
        '&:first-child': {
            borderRight: '1px solid #F1F1F1',
        },
        '& .MuiInputBase-root': {
            fontSize: '14px',
            color: '#000000',
        },
        '& .MuiInput-underline:before': {
            border: 'none',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: 'none',
        },
        '& .MuiInput-underline:after': {
            border: 'none',
        },
    },
}));
