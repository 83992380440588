import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
    autoComplete: {
        marginBottom: 4,
        '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
            borderRadius: 5,
            border: '1px solid #989898',
            '& .MuiAutocomplete-input:first-child': {
                padding: '11px 12px',
                fontSize: 14,
                backgroundColor: 'white',
            },
        },
        '& .MuiInput-underline:before, & .MuiInput-underline:after, .MuiInput-underline:hover:before': {
            border: 'none',
        },
        '& label.MuiInputLabel-shrink': {
            transform: 'translate(0, -4px)',
            fontSize: 14,
            fontWeight: 'bold',
            color: 'black',
        },
    },
});

export default useStyles;
