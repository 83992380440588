import Cookies from 'js-cookie';

// eslint-disable-next-line import/prefer-default-export
export const checkB2BPermission = (role) => {
    const data = Cookies.getJSON('customerB2B');
    if (data && data.status) {
        if (data.roles.length === 1 && data.roles[0].role === 'Aheadworks_Ca::all') {
            return true;
        } if (data.status_company === 'pending_approval') {
            if (role === 'companies_view') {
                return true;
            }
        } else {
            const roleArr = data.roles.map((items) => items?.role?.split('::')[1]);
            if (roleArr.includes(role)) {
                return true;
            }
        }
    }
    return false;
};

export const getB2BData = () => Cookies.getJSON('customerB2B');
