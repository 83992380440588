/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Typography from '@common_typography';
import Radio from '@material-ui/core/Radio';
import classNames from 'classnames';
import useStyles from '@core_modules/checkout/components/pickupradioitem/style';

const PickupRadioItem = (props) => {
    const styles = useStyles();
    const {
        onChange = () => { }, disabled = false, customValue,
        customChange, city, code, name, country_id, items,
        lat, long, postcode, region, street, telephone, zone,
    } = props;

    const handleChange = () => {
        const itemValue = {
            city,
            code,
            country_id,
            items,
            lat,
            long,
            name,
            postcode,
            region,
            street,
            telephone,
            zone,
        };
        if (!disabled) {
            onChange(code);
            customChange(itemValue);
        }
    };

    if (disabled) return null;

    return (
        <div
            className={styles.root}
            onClick={handleChange}
        >
            <Radio className={styles.customRadio} color="default" size="small" checked={customValue === code} />

            <div className={classNames(styles.labelContainer)}>
                <Typography variant="p" className={styles.storeName}>
                    {name}
                </Typography>
                <Typography variant="p" className={styles.storeAddress}>
                    {street}
                    {' '}
                    {city}
                    {', '}
                    {region}
                    {' '}
                    {postcode}
                </Typography>
            </div>
        </div>
    );
};

export default PickupRadioItem;
