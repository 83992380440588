/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useTranslation } from '@i18n';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import gqlService from '@core_modules/checkout/services/graphql';
import useStyles from '@core_modules/checkout/pages/default/components/ModalSelectStore/style';
import Radio from '@common_forms/Radio';
import PickupRadioItem from '@core_modules/checkout/components/pickupradioitem/index';

const ModalSelectStore = ({
    checkout,
    setCheckout,
    listStores = [],
}) => {
    const { t } = useTranslation(['common', 'checkout', 'validate']);
    const styles = useStyles();
    const [stores, setStores] = React.useState(listStores);
    const [setPickupStore] = gqlService.setPickupStore();
    // const [loading, setLoading] = React.useState(false);

    const handleSave = async (item) => {
        // await setLoading(true);
        const pickupInformation = {
            pickup_person_email: checkout.data.customer.email || '',
            pickup_person_name: checkout.data.customer.firstname || '',
            pickup_person_phone: checkout.data.customer.telephone || '',
        };
        if (Object.keys(pickupInformation).length > 0) {
            const state = {
                ...checkout,
                loading: {
                    ...checkout.loading,
                    all: false,
                    shipping: false,
                    extraFee: true,
                    order: true,
                },
            };
            setCheckout(state);
            await setPickupStore({
                variables: {
                    cart_id: checkout.data.cart.id,
                    code: item.code,
                    extension_attributes: pickupInformation,
                    store_address: {
                        city: item.city,
                        country_code: item.country_id,
                        name: item.name,
                        postcode: item.postcode,
                        region: item.region,
                        street: [item.street],
                        telephone: item.telephone,
                    },
                },
            }).then(async (res) => {
                const paymentMethod = res.data.setPickupStore.available_payment_methods.map((method) => ({
                    ...method,
                    label: method.title,
                    value: method.code,
                    image: null,
                }));
                await setCheckout({
                    ...checkout,
                    data: {
                        ...checkout.data,
                        cart: {
                            ...checkout.data.cart,
                            ...res.data.setPickupStore,
                        },
                        paymentMethod,
                    },
                    selectStore: {
                        ...item,
                    },
                    pickupInformation,
                    loading: {
                        ...checkout.loading,
                        all: false,
                        shipping: false,
                        payment: false,
                        extraFee: false,
                        order: false,
                    },
                    error: {
                        selectStore: false,
                        pickupInformation: false,
                    },
                });
                // await setLoading(false);
            }).catch((err) => {
                window.toastMessage({
                    open: true,
                    variant: 'error',
                    text: err.message.split(':')[1] || t('checkout:message:serverError'),
                });
                // setLoading(false);
            });
        } else {
            await setCheckout({
                ...checkout,
                selectStore: {
                    ...item,
                },
                error: {
                    ...checkout.error,
                    pickupInformation: true,
                },
            });

            // await setLoading(false);
        }
    };

    React.useEffect(() => {
        setStores(listStores);
    }, [listStores]);

    return (
        <div className={styles.container}>
            {
                stores.length > 0 ? (
                    <Radio
                        value={checkout.selectStore.code || ''}
                        propsItem={{
                            customChange: handleSave,
                            customValue: checkout.selectStore.code || '',
                        }}
                        valueData={stores}
                        CustomItem={PickupRadioItem}
                    />
                ) : (
                    <Alert className="m-15" severity="warning">
                        {t('checkout:storesNotFound')}
                    </Alert>
                )
            }
        </div>
    );
};

export default ModalSelectStore;
